<template>
  <v-container fluid class="ma-4">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Parametrização dos tipos de movimentação corretora vs tipo movimentação da Operadora</h1>
      </v-col>
    </v-row>
    <v-row class="align-center mt-10">
      <v-col cols="12" xl="2" lg="3" md="4" sm="11" xs="11">
        <label class="label">Operadora</label>
        <v-autocomplete
          :items="insuranceCarriers"
          :loading="isLoadingCarrier"
          v-model="insuranceCarrierId"
          item-text="xipp_commercial_name"
          item-value="id"
          class="mt-2"
          placeholder="Selecione a operadora"
          outlined
          color="textPrimary"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="4" sm="11" xs="11">
        <v-btn
          x-large
          dark
          color="textPrimary"
          width="250px"
          @click="getParametrizationByInsuranceCarrierId()"
        >
          Pesquisar
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="12" xl="2" lg="2" md="4" sm="11" xs="11" class="justify-end align-end d-flex mr-5">
        <v-btn
          :loading="isLoadingNewConfig"
          class="white--text"
          x-large
          block
          color="textPrimary"
          @click="openModalParametrization({}, 'isNew')"
          :disabled="!insuranceCarrierId"
        >
          Nova Configuração
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12 pr-8">
        <v-data-table
          :headers="header"
          :page.sync="page"
          :items-per-page="10"
          :loading="isLoadingTable"
          :items="parametrizations"
          class="elevation-1"
          sort-by="minLife"
          hide-default-footer
          item-key="id"
        >
          <template v-slot:no-data>
            <span>Nenhum dado encontrado.</span>
          </template>
          <template v-slot:[`item.insuranceCarrierId`] = '{ item }'>
            {{ convertIdInsuranceCarrierInName(item.insuranceCarrierId) }}
          </template>
          <template v-slot:[`item.actions`] = '{ item }'>
            <v-btn icon color="primary" @click="openModalParametrization(item, 'isEdit')">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="openConfirmDeleteDialog(item)">
              <v-icon >mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :total-visible="9"
            @input="getParametrizationByInsuranceCarrierId()"
          />
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDialog" max-width="700px">
      <v-card class="pa-5">
        <v-card-title class="text-h5 justify-center primary--text">
          Você tem certeza que deseja excluir essa configuração?
        </v-card-title>
        <v-card-actions>
        <v-row class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="closeConfirmDeleteDialog()"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              block
              style="color: white"
              @click="confirmDeleteConfig()"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12 pr-8" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <ParametrizationBrokerAndInsuranceCarrierDialog :dataProps="data" :typeModal="type" @getParametrizationByInsuranceCarrierId="getParametrizationByInsuranceCarrierId" ref="ParametrizationBrokerAndInsuranceCarrierDialog" />
  </v-container>
</template>

<script>
import CarrierService from '@/services-http/contract/CarrierService';
import ParametrizationBrokerAndInsuranceCarrierDialog from '@/components/ParametrizationMovementTypesDialog/parametrizationBrokerAndInsuranceCarrierDialog.vue';
import MovementRecordParametrizationService from '@/services-http/sdi/MovementRecordParametrizationService';

export default ({
  components: { ParametrizationBrokerAndInsuranceCarrierDialog },

  data: () => ({
    isLoadingNewConfig: false,
    isLoadingCarrier: false,
    isLoadingTable: false,
    confirmDeleteDialog: false,
    insuranceCarriers: [],
    insuranceCarrierId: '',
    idForDelete: 0,
    type: '',
    data: {},
    page: 1,
    totalPages: 0,
    header: [
      {
        text: 'Operadora',
        value: 'insuranceCarrierId',
        align: 'center',
        sortable: true,
      },
      {
        text: '"De"',
        value: 'movementRecordTypeFrom',
        align: 'center',
        sortable: true,
      },
      {
        text: '"Para"',
        value: 'movementRecordTypeTo',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Campo',
        value: 'fieldName',
        align: 'center',
        sortable: true,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ],
    parametrizations: [],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  async mounted() {
    await this.loadInsuranceCarriers();
  },

  methods: {
    async loadInsuranceCarriers() {
      this.isLoadingCarrier = true;
      await this.carrierService.FindAll().then((response) => {
        if (response && response.data.length) {
          this.insuranceCarriers = response.data;
          this.isLoadingCarrier = false;
        }
      }).catch((error) => {
        this.isLoadingCarrier = false;
      });
    },
    getParametrizationByInsuranceCarrierId() {
      if (this.insuranceCarrierId) {
        this.isLoadingTable = true;
        this.movementRecordParametrizationService.FindAllByFilters({ insuranceCarrierId: this.insuranceCarrierId, page: this.page - 1, size: 10 }).then((response) => {
          if (response && response.data) {
            this.parametrizations = response.data.content;
            this.totalPages = response.data.totalPages;
          }
          this.isLoadingTable = false;
        }).catch((error) => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao buscar as parametrizações');
          this.isLoadingTable = false;
        });
      } else {
        this.customizeSnackbarMessage('error', 'Selecione uma operadora');
      }
    },
    convertIdInsuranceCarrierInName(id) {
      let text = '';
      if (id) {
        this.insuranceCarriers.forEach((element) => {
          if (element && element.id === id) {
            text = element.name;
          }
        });
      }
      return text;
    },
    async openModalParametrization(item, type) {
      if (type === 'isNew') {
        this.isLoadingNewConfig = true;
      }
      this.type = type;
      if (item && type === 'isNew') {
        this.data.insuranceCarrierId = this.insuranceCarrierId;
      }
      if (item && type === 'isEdit') {
        this.data = item;
      }
      await this.$refs.ParametrizationBrokerAndInsuranceCarrierDialog.open();
      this.isLoadingNewConfig = false;
    },
    openConfirmDeleteDialog(item) {
      this.idForDelete = item.id;
      this.confirmDeleteDialog = true;
    },
    closeConfirmDeleteDialog() {
      this.confirmDeleteDialog = false;
    },
    async confirmDeleteConfig() {
      await this.movementRecordParametrizationService.Delete(this.idForDelete).then((response) => {
        this.getParametrizationByInsuranceCarrierId();
        this.customizeSnackbarMessage('success', 'Parametrização deletada com sucesso');
        this.closeConfirmDeleteDialog();
      }).catch((error) => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao deletar a parametrização');
      });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },

    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
  },

  created() {
    this.carrierService = new CarrierService();
    this.movementRecordParametrizationService = new MovementRecordParametrizationService();
  },
});
</script>
