<template>
  <v-container fluid>
    <v-form v-model="validationForm" lazy-validation ref="validation">
      <v-row justify="center">
        <v-dialog v-model="parametrizationDialog" persistent max-width="1080">
          <v-card class="pa-5">
            <v-card-title class="pl-3 ml-3">
              <v-row>
                <v-col class="text-h5 primary--text mb-5" cols="12">
                  <div v-if="typeModal === 'isNew'">Preencha os campos abaixo para criar uma nova configuração.</div>
                  <div v-else>Preencha os campos abaixo para editar a configuração.</div>
                </v-col>
                <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4">
                  "De"
                  <v-autocomplete
                    :items="movementTypes"
                    item-text="name"
                    item-value="code"
                    v-model="parametrization.movementRecordTypeFrom"
                    class="font-weight-regular"
                    outlined
                    placeholder="Informe a movimentação"
                    color="textPrimary"
                  />
                </v-col>
                <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4">
                  "Para"
                  <v-autocomplete
                    :items="movementTypes"
                    item-text="name"
                    item-value="code"
                    v-model="parametrization.movementRecordTypeTo"
                    class="font-weight-regular"
                    outlined
                    placeholder="Informe a movimentação"
                    color="textPrimary"
                  />
                </v-col>
                <v-col class="text-subtitle-1 primary--text font-weight-medium" cols="12" md="4">
                  Campo
                  <v-autocomplete
                    :items="fields"
                    item-text="label"
                    item-value="value"
                    v-model="parametrization.fieldName"
                    class="font-weight-regular"
                    outlined
                    placeholder="Selecione o campo"
                    color="textPrimary"
                  />
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-actions>
              <v-row class="mt-6">
                <v-spacer></v-spacer>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                  <v-btn
                    class="mr-3 pa-6"
                    color="#3B495B"
                    outlined
                    block
                    @click="close()"
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                  <v-btn
                    :loading="isLoadingButton"
                    class="mr-6 pa-6"
                    color="#3B495B"
                    block
                    style="color: white"
                    @click="typeModal === 'isNew' ? saveParametrization() : updateParametrization()"
                  >
                    <div v-if="typeModal === 'isEdit'">Salvar</div>
                    <div v-else>Adicionar</div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import DataSourceService from '@/services-http/sdi/DataSourceService';
import MovementRecordTypeService from '@/services-http/sdi/MovementRecordTypeService';
import MovementRecordParametrizationService from '@/services-http/sdi/MovementRecordParametrizationService';

export default ({
  name: 'ParametrizationBrokerAndInsuranceCarrierDialog',

  data: () => ({
    isLoadingButton: false,
    parametrizationDialog: false,
    validationForm: false,
    movementTypes: [],
    fields: [],
    parametrization: {
      movementRecordTypeFrom: '',
      movementRecordTypeTo: '',
      fieldName: '',
      insuranceCarrierId: 0,
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  props: {
    dataProps: Object,
    typeModal: String,
  },

  methods: {
    async open() {
      await this.loadMovementTypes();
      await this.loadField();
      if (this.typeModal === 'isNew') {
        this.clearFields();
      }
      if (this.typeModal === 'isEdit') {
        this.parametrization = this.dataProps;
      }
      if (this.dataProps && this.dataProps.insuranceCarrierId) {
        this.parametrization.insuranceCarrierId = this.dataProps.insuranceCarrierId;
      }
      this.parametrizationDialog = true;
    },
    async loadMovementTypes() {
      await this.movementRecordTypeService.FindAll().then((response) => {
        if (response && response.data) {
          this.movementTypes = response.data;
        }
      });
    },
    async loadField() {
      await this.dataSourceService.FindAll().then((response) => {
        let movementRecords = [];
        if (response && response.data) {
          movementRecords = response.data.find((item) => item.value === 'registro_movimentacao').columns;
          this.fields = movementRecords;
        }
      });
    },
    close() {
      this.parametrizationDialog = false;
    },
    saveParametrization() {
      if (this.parametrization.movementRecordTypeFrom && this.parametrization.movementRecordTypeTo && this.parametrization.fieldName) {
        this.isLoadingButton = true;
        this.movementRecordParametrizationService.Save(this.parametrization).then((response) => {
          this.$emit('getParametrizationByInsuranceCarrierId');
          this.close();
          this.clearFields();
          this.isLoadingButton = false;
          this.customizeSnackbarMessage('success', 'Parametrização cadastrada com sucesso');
        }).catch((error) => {
          this.isLoadingButton = false;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao cadastrar a parametrização');
        });
      } else {
        this.customizeSnackbarMessage('error', 'Confira os campos para prosseguir');
      }
    },
    updateParametrization() {
      if (this.parametrization.movementRecordTypeFrom && this.parametrization.movementRecordTypeTo && this.parametrization.fieldName) {
        this.isLoadingButton = true;
        this.movementRecordParametrizationService.Update(this.parametrization.id, this.parametrization).then((response) => {
          this.$emit('getParametrizationByInsuranceCarrierId');
          this.close();
          this.clearFields();
          this.isLoadingButton = false;
          this.customizeSnackbarMessage('success', 'Parametrização editada com sucesso');
        }).catch((error) => {
          this.isLoadingButton = false;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao editar a parametrização');
        });
      } else {
        this.customizeSnackbarMessage('error', 'Confira os campos para prosseguir');
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    clearFields() {
      this.parametrization = {
        movementRecordTypeFrom: '',
        movementRecordTypeTo: '',
        fieldName: '',
      };
    },
  },
  created() {
    this.dataSourceService = new DataSourceService();
    this.movementRecordTypeService = new MovementRecordTypeService();
    this.movementRecordParametrizationService = new MovementRecordParametrizationService();
  },
});
</script>
